$textarea-padding: $control-padding-horizontal !default
$textarea-max-height: 40em !default
$textarea-min-height: 8em !default

$textarea-colors: $form-colors !default

%input-textarea
  @extend %input
  //box-shadow: $input-shadow
  max-width: 100%
  width: 100%
  //&[readonly]
    //box-shadow: none
  // Colors
  @each $name, $pair in $textarea-colors
    $color: nth($pair, 1)
    &.is-#{$name}
      border-color: $color
      //&:focus,
      //&.is-focused,
      //&:active,
      //&.is-active
      //  box-shadow: $input-focus-box-shadow-size bulmaRgba($color, 0.25)
  // Sizes
  &.is-small
    +control-small
  &.is-medium
    +control-medium
  &.is-large
    +control-large
  // Modifiers
  &.is-fullwidth
    display: block
    width: 100%
  &.is-inline
    display: inline
    width: auto

.input
  @extend %input-textarea
  &.is-rounded
    border-radius: $radius-rounded
    padding-left: calc(#{$control-padding-horizontal} + 0.375em)
    padding-right: calc(#{$control-padding-horizontal} + 0.375em)
  &.is-static
    background-color: transparent
    border-color: transparent
    //box-shadow: none
    padding-left: 0
    padding-right: 0

.textarea
  @extend %input-textarea
  display: block
  max-width: 100%
  min-width: 100%
  padding: $textarea-padding
  resize: vertical
  &:not([rows])
    max-height: $textarea-max-height
    min-height: $textarea-min-height
  &[rows]
    height: initial
  // Modifiers
  &.has-fixed-size
    resize: none
