$strengthlevelblue: #004977;

$facebook: #4267b2;
$tablehighlight: #F7EDBA;
$gold: gold;
$lightGold: bulmaLighten($gold, 40%);
$custom-colors: (
        "facebook": $facebook,
        "gold": $gold,
        "strengthlevel": $strengthlevelblue,
        "tablehighlight": $tablehighlight
);

$primary: #008ee6;

$button-background-color: $grey-lightest;
$button-border-color: transparent;

$family-sans-serif: sans-serif;
$family-primary: $family-sans-serif;

$text: $black;

$border: $grey-light;
$border-hover: $grey;
$input-focus-border-color: $grey-dark;
$input-arrow: $border;

$table-cell-text-align: inherit;

$modal-z: 2147483646; // Was 2147483641 over 999 now 2147483645 + 1

$outline-color: #BCC2D9;