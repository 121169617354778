.modal-card-search {
  background-color: $modal-card-head-background-color;
  padding: $modal-card-head-padding;
  border-bottom: $modal-card-head-border-bottom
}

@include mobile {
  .modal-card-search {
    padding: $modal-card-head-padding / 2;

    .field:not(:last-child) {
      margin-bottom: $modal-card-head-padding / 2;
    }
  }
}

.exercise-modal {
  .media {
    border-bottom: 1px solid #eeeeee;
    padding: map-get($spacing-values, "3") map-get($spacing-values, "4");
    content-visibility: auto;
    contain-intrinsic-size: auto 75px;
  }

  .media:last-child {
    box-shadow: $shadow;
  }

  .media + .media {
    border-top: none;
    margin-top: 0;
  }

  .media-left {
    @include ltr_property("margin", map-get($spacing-values, "3"));
  }

  @include mobile {
    .modal-background {
      background-color: #2C2C2C;
    }
  }
}


.control--modal {
  input {
    cursor: pointer;
    @include ltr_property("padding", 2.5em);
  }

  &::after {
    @extend %arrow;
    border-color: $input-arrow;
    @include ltr-position(1.125em);
    z-index: 4;
  }

  &:hover {
    &::after {
      border-color: $input-hover-color;
    }
  }
}

html.modal-open {
  pointer-events: none;
  overflow: hidden;

  .modal {
    pointer-events: auto;
  }
}
