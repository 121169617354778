@font-face {
	font-family: "strengthlevelfontcore";
	src: url("strengthlevelfontcore.woff2?6fcee3fe46a40b2f1dfd823cabd14e0e") format("woff2"),
url("strengthlevelfontcore.woff?6fcee3fe46a40b2f1dfd823cabd14e0e") format("woff");
	font-display: block;
}

.slicon {
	line-height: 1;
}

.slicon:before {
	font-family: strengthlevelfontcore !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}

.slicon-fa-envelope:before {
	content: "\f0e0";
}
.slicon-fa-check:before {
	content: "\f00c";
}
.slicon-fa-magnifying-glass:before {
	content: "\f002";
}
.slicon-fa-angle-down:before {
	content: "\f107";
}
.slicon-fa-right-left:before {
	content: "\f362";
}
.slicon-fa-triangle-exclamation:before {
	content: "\f071";
}
.slicon-fa-flask:before {
	content: "\f0c3";
}
.slicon-fa-circle-info:before {
	content: "\f05a";
}
.slicon-fa-table:before {
	content: "\f0ce";
}
.slicon-fa-graduation-cap:before {
	content: "\f19d";
}
.slicon-fa-mars:before {
	content: "\f222";
}
.slicon-fa-venus:before {
	content: "\f221";
}
.slicon-fa-facebook:before {
	content: "\f09a";
}
.slicon-fa-x-twitter:before {
	content: "\e61b";
}
.slicon-fa-instagram:before {
	content: "\f16d";
}
