@import "../utilities/mixins"

=typography-size($target:'')
  @each $size in $sizes
    $i: index($sizes, $size)
    .is-size-#{$i}#{if($target == '', '', '-' + $target)}
      font-size: $size !important

+typography-size()

+mobile
  +typography-size('mobile')

$alignments: ('centered': 'center', 'left': 'left', 'right': 'right')

@each $alignment, $text-align in $alignments
  .has-text-#{$alignment}
    text-align: #{$text-align} !important

.is-capitalized
  text-transform: capitalize !important

.is-lowercase
  text-transform: lowercase !important

.is-uppercase
  text-transform: uppercase !important

.is-italic
  font-style: italic !important
  
.is-underlined
  text-decoration: underline !important

.has-text-weight-normal
  font-weight: $weight-normal !important
.has-text-weight-bold
  font-weight: $weight-bold !important

